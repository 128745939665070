<h1>Forgot Your User Name/Password?</h1>
<div id="forgotPassword" *ngIf="resetStatus === PasswordResetStatus.NotSet">
  <form [formGroup]="form">
      <div>Enter your email address to receive it.</div>
      <div>
        <label for="emailAddress">Email Address:</label>
        <span
          *ngIf="form.get('emailAddress')?.invalid && (form.get('emailAddress')?.touched || form.get('emailAddress')?.dirty)">
          A valid email address is required.</span>
        <input id="emailAddress" type="text" formControlName="emailAddress" name="emailAddress" />
      </div>
      <div>
        <label for="confirmEmailAddress">Confirm Email Address:</label>
                <span
                  *ngIf="form.get('confirmEmailAddress')?.invalid && (form.get('confirmEmailAddress')?.touched || form.get('confirmEmailAddress')?.dirty)">
                  Email addresses don't match.</span>

        <input id=confirmEmailAddress type="text" formControlName="confirmEmailAddress" name="confirmEmailAddress" />
      </div>
      <div id="statusMessage">
        <img src="/assets/img/wait24trans.gif" *ngIf="isProcessing"/>
        <div [innerHTML]="statusMessage"></div>
      </div>
      <div id="buttons">
          <button id="SendUserName" type="button" [disabled]="form.invalid" (click)="sendUserName()">Request User Name</button>
          <button id="SendPassword" type="button" [disabled]="form.invalid || isProcessing" (click)="resetPassword()">Reset Password</button>
      </div>
  </form>
</div>
<div id="verificationCode" *ngIf="resetStatus === PasswordResetStatus.ShowVerificationCode">
  <form [formGroup]="formCode">
    <div>A verification code has been sent to the email address provided.  Please copy the code into the verfication field below.  Check your spam folder if you don't see the verification email.</div>
    <label for="verificationCode">Verification Code:</label>
    <input type="text" id="uiVerificationCode" formControlName="uiVerificationCode" name="uiVerificationCode" />
      <div id="statusMessage">
        <img src="/assets/img/wait24trans.gif" *ngIf="isProcessing" />
        <div [innerHTML]="statusMessage"></div>
      </div>
    <button id="checkCode" type="button" [disabled]="formCode.invalid || isProcessing" (click)="checkCode()">Check</button>
  </form>
</div>
<div id="changePassword" *ngIf="resetStatus === PasswordResetStatus.ChangePasswordForm">
  <form [formGroup]="formChangePassword">
    <div>Please type and confirm your new password.</div>
    <label for="newPassword">Password:</label>
    <span *ngIf="formChangePassword.get('newPassword')?.invalid && (formChangePassword.get('newPassword')?.touched || formChangePassword.get('newPassword')?.dirty)">
      A new password is required.</span>
    <input type="password" id="newPassword" formControlName="newPassword" name="newPassword" />
    <label for="confirmPassword">Confirm Password:</label>
    <span
      *ngIf="formChangePassword.get('confirmPassword')?.invalid && (formChangePassword.get('confirmPassword')?.touched || formChangePassword.get('confirmPassword')?.dirty)">
      The confirmation password is required and must match the new password.</span>
    <input type="password" id="confirmPassword" formControlName="confirmPassword" name="confirmPassword" />
      <div id="statusMessage">
        <img src="/assets/img/wait24trans.gif" *ngIf="isProcessing" />
        <div [innerHTML]="statusMessage"></div>
      </div>
    <button type="button" [disabled]="formChangePassword.invalid || isProcessing" (click)="changePassword()">Update</button>
  </form>
</div>
<div id="changePasswordSuccess" *ngIf="resetStatus === PasswordResetStatus.ChangePasswordSuccess" [innerHTML]="statusMessage">
</div>