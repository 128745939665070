<h1>User Profile</h1>
<div id="profile">
<form id="changePassword" [formGroup]="formPassword">
  <h2>Change Your Password:</h2>
  <div><label for="newPassword">New Password:</label></div>
  <div>
    <span *ngIf="formPassword.get('newPassword')?.invalid && (formPassword.get('newPassword')?.touched || formPassword.get('newPassword')?.dirty)">
      The new password is required and must be at least three characters.</span>
  </div>
  <div><input id="newPassword" type="password" formControlName="newPassword"/></div>

  <div><label for="confirmPassword">Confirm Password:</label></div>
  <div>
    <span *ngIf="formPassword.get('confirmPassword')?.invalid && (formPassword.get('confirmPassword')?.touched || formPassword.get('confirmPassword')?.dirty)">
      The confirmation password is required and must match the new password.</span>
  </div>
  <div><input id="confirmPassword" type="password" formControlName="confirmPassword" /></div>

  <div><span [innerHTML]="passwordStatus"></span></div>
  <div><button type="button" [disabled]="formPassword.invalid || isProcessing" (click)="changePassword()">Change Password</button></div>
</form>

<form id="changeEmailAddress" [formGroup]="formEmailAddress">
  <h2>Change Your Email Address:</h2>
  <div><label>New Email Address:</label></div>
  <div>
    <span
      *ngIf="formEmailAddress.get('newEmailAddress')?.invalid && (formEmailAddress.get('newEmailAddress')?.touched || formEmailAddress.get('newEmailAddress')?.dirty)">
      The email address is required and must be a valic email.</span>
  </div>
  <div><input type="email" id="emailAddress" formControlName="newEmailAddress" /></div>
  <div><span [innerHTML]="emailStatus"></span></div>
  <div id="buttons">
    <button type="button" [disabled]="formEmailAddress.invalid || showEmailVerification == true" (click)="setShowEmailVerification()">Change Email Address</button>
  </div>
</form>

<app-email-verification *ngIf="showEmailVerification"
  (verificationEvent)="receiveEmailVerificationStatus($event)"
  emailAddress="{{formEmailAddress.get('newEmailAddress')?.value}}"></app-email-verification>

<form id="changeUsername" [formGroup]="formUsername">
  <h2>Change Your Login Username:</h2>
  <div><label for="username">New Username</label></div>
  <div><span
    *ngIf="formUsername.get('newUsername')?.invalid && (formUsername.get('newUsername')?.touched || formUsername.get('newUsername')?.dirty)">
    The new username is required.</span></div>
  <div><input id="username" type="text" formControlName="newUsername" /></div>
  <div>
    <span [innerHTML]="usernameStatus">&nbsp;</span>
  </div>
  <div><button type="button" [disabled]="formUsername.invalid || isProcessing" (click)="changeUsername()">Change Login Username</button></div>
</form>
</div>

