import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-page-status',
  templateUrl: './page-status.component.html',
  styleUrls: ['./page-status.component.scss']
})
export class PageStatusComponent implements OnInit {
  @Input() pageName: string = '';
  
  globalPageStatusText: string = '';
  pageStatusText: string = '';

  constructor(private api: ApiService) { }

  async ngOnInit() {
    let response = await this.api.PageStatus(this.pageName);
    if (response.RequestSuccessful) {
      if (response.GlobalPageStatus !== null && response.GlobalPageStatus.StatusEnabled && response.GlobalPageStatus.StatusText !== '') {
        this.globalPageStatusText = response.GlobalPageStatus.StatusText;
      }
      if (response.PageStatus !== null && response.PageStatus.StatusEnabled && response.PageStatus.StatusText !== '') {
        this.pageStatusText = response.PageStatus.StatusText;
      }
    }
  }

}
