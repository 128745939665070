import { UserRoles } from "src/app/enums/login.enum";
import { environment } from "src/environments/environment";
import { IUserData } from "../interfaces/user-data";

export class UserData implements IUserData {
  userId: number = 0;
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  emailAddress: string = '';
  created: Date | null = null;
  updated: Date | null = null;
  lastAccess: Date | null = null;
  userRole: UserRoles | null = null;
  isActive: boolean | null = null;

  constructor(user: any) {
    if (user != null) {
      if (typeof user === "string" && user.length > 0) {
        let obj = JSON.parse(user);
        this.userId = obj.userId;
        this.username = obj.username;
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.emailAddress = obj.emailAddress;
        this.created = new Date(obj.created);
        this.updated = new Date(obj.updated);
        this.lastAccess = new Date(obj.lastAccess);
        this.userRole = obj.userRole;
        this.isActive = obj.isActive;
      } else if (user.UserId !== undefined) {
        this.userId = user.UserId;
        this.username = user.Username;
        this.firstName = user.FirstName;
        this.lastName = user.LastName;
        this.emailAddress = user.EmailAddress;
        if (user.created !== undefined) {
          this.created = new Date(parseInt(user.Created.substr(6)));
        }
        if (user.updated !== undefined) {
          this.updated = new Date(parseInt(user.Updated.substr(6)));
        }
        if (user.lastAccess !== undefined) {
          this.lastAccess = new Date(parseInt(user.LastAccess.substr(6)));
        }
        this.userRole = user.UserRole;
        this.isActive = user.IsActive;
      } else {
      this.userId = user.userId;
      this.username = user.username;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.emailAddress = user.emailAddress;
      if (user.created !== undefined) {
        this.created = new Date(parseInt(user.created.substr(6)));
      }
      if (user.updated !== undefined) {
        this.updated = new Date(parseInt(user.updated.substr(6)));
      }
      if (user.lastAccess !== undefined) {
        this.lastAccess = new Date(parseInt(user.lastAccess.substr(6)));
      }
      this.userRole = user.userRole;
      this.isActive = user.isActive;
    }
    }
  }

  get timedOut(): boolean {
    let result = false;
    result = this.minutesAgo(this.lastAccess) >= environment.userTimeoutMinutes;
    return result;
  }

  get isAdmin(): boolean {
    return this.userRole === UserRoles.Admin;
  }

  private minutesAgo(date: Date | null): number {
    if (date === null) return 0;
    let now = new Date();
    let diff = (now.getTime() - date.getTime()) / (1000 * 60);
    return diff;
  }

  parseDateTime(json: string): Date {
    let temp = json.substring(6, json.indexOf(')', 6));
    let result = new Date(parseInt(temp));
    return result;
  }

}
