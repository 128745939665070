<app-header></app-header>
<app-menubar></app-menubar>
<div id="main">
  <app-menu></app-menu>
  <div class="route">
    <router-outlet></router-outlet>
  </div>
  <app-sidebar></app-sidebar>
</div>
<app-footer></app-footer>
