import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
//import { HttpHeaders } from '@angular/common/http';

//var headers = new HttpHeaders();

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})

export class PageNotFoundComponent implements OnInit {

  //public url: string = "";

  constructor(private titleService: Title, public router: Router) {
    this.titleService.setTitle("More Stuff - Page Not Found")
  }

  ngOnInit() {
    // this.url = this.router.url;

    // console.log('url: ', this.url);

    // headers.append("Cache-Control", "no-cache, no-store, must-revalidate"); // HTTP 1.1.
    // headers.append("Pragma", "no-cache"); // HTTP 1.0.
    // headers.append("Expires", "0"); // Proxies.
  }

}