import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { UserData } from '../dto/user-data';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  currentUser: UserData | null = null;
  isProcessing: boolean = false;

  constructor(private router: Router, private userService: UserService, private http: HttpClient, private api: ApiService) {
    this.userService.currentUserChange.subscribe((user: UserData | null) => {
      this.currentUser = user;
    })
  }

  ngOnInit() {
    this.isUserAuthorized();
  }

  isUserAuthorized(): boolean {
    this.currentUser = this.userService.currentUser;
    if (this.currentUser === null ||
      (!(this.currentUser.isAdmin))) {
      this.router.navigate(['/','unauthorized']);
      return false;
    }
    return true;
  }

}
