<h1>Site Login</h1>

<h2>Returning User</h2>
<h3>Log In</h3>

<div id="loginForm">
  <form [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm)">
    <label>User Name:</label>
    <div *ngIf="form['userName']?.touched && form['userName']?.errors"><span>The user name must have at least two characters.</span></div>
    <input formControlName="userName">
    <label>Password:</label>
    <div *ngIf="form['password']?.touched && form['password']?.errors"><span>The password must have at least two characters.</span></div>
    <input formControlName="password" type="password">
    <div id="loginStatus">{{loginStatus}}</div>
    <a routerLink="/forgotpassword" routerLinkActive="router-link-active">Forgot Password</a>
    <div id="loginButtons"><button type="submit" [disabled]="loading || loginForm.invalid">Login</button></div>
  </form>
</div>
<div id="createUserDiv"><a href="/newuser">Create An Account</a></div>
