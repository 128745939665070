<div id="newUserForm">
<div *ngIf="showUserForm">
  <h1>Create New User</h1>

  <form id="userForm" [formGroup]="userForm">
    <div>
      <div>
        <label>Username:</label>
        <span *ngIf="userForm.get('username')?.invalid && (userForm.get('username')?.touched || userForm.get('username')?.dirty)">
          A valid username is required.</span>
      </div>
      <input type="text" formControlName="username" id="username" />
    </div>
    <div>
      <div>
        <label>First Name:</label>
        <span *ngIf="userForm.get('firstName')?.invalid && (userForm.get('firstName')?.touched || userForm.get('firstName')?.dirty)">
          Your first name is required.</span>
      </div>
      <input type="text" formControlName="firstName" id="firstName" />
    </div>
    <div>
      <div>
        <label>Last Name:</label>
        <span *ngIf="userForm.get('lastName')?.invalid && (userForm.get('lastName')?.touched || userForm.get('lastName')?.dirty)">
          Your last name is required.</span>
      </div>
      <input type="text" formControlName="lastName" id="lastName" />
    </div>
    <div>
      <div>
        <label>Password:</label>
        <span *ngIf="userForm.get('password')?.invalid && (userForm.get('password')?.touched || userForm.get('password')?.dirty)">
          The password must be at least three characters and less than or equal to 250 characters.</span>
      </div>
      <input formControlName="password" type="password" id="password" name="password" ref="password" />
    </div>
    <div>
      <div>
        <label>Confirm Password:</label>
        <span
          *ngIf="userForm.get('confirmPassword')?.invalid && (userForm.get('confirmPassword')?.touched || userForm.get('confirmPassword')?.dirty)">
          Passwords don't match.</span>
      </div>
      <input formControlName="confirmPassword" type="password" id="confirmPassword" name="confirmPassword" />
    </div>
    <div>
      <div>
        <label>Email:</label>
        <span
          *ngIf="userForm.get('emailAddress')?.invalid && (userForm.get('emailAddress')?.touched || userForm.get('emailAddress')?.dirty)">
          A valid email address is required.</span>
      </div>
      <input type="text" formControlName="emailAddress" name="emailAddress" />
    </div>
    <div>
      <div>
        <label>Confirm Email:</label>
        <span
          *ngIf="userForm.get('confirmEmailAddress')?.invalid && (userForm.get('confirmEmailAddress')?.touched || userForm.get('confirmEmailAddress')?.dirty)">
          Email addresses don't match.</span>
      </div>
      <input type="text" formControlName="confirmEmailAddress" name="confirmEmailAddress" />
    </div>
    <div class="statusMessage" *ngIf="statusMessage.length > 0">{{statusMessage}}</div>
    <div class="buttons">
      <button id="createUser" type="button" [disabled]="userForm.invalid || isProcessing" (click)="validateUser()">Create User</button>
    </div>
  </form>
</div>

<div *ngIf="!showUserForm">
  <h1>Verification Code Validation</h1>
  <form id="codeForm" [formGroup]="codeForm">
    <p>Email validation sent to <b>{{userForm.get("emailAddress")?.value}}</b>. Please leave this page open, check
      your email and provide the verification code found in the email.</p>
    <div class="statusMessage" *ngIf="statusMessage.length > 0">{{statusMessage}}</div>
    <div>
      <div>
        <label>Verification Code:</label>
        <span *ngIf="!isCodeValid()">A valid code is required.</span>
      </div>
      <input type="text" formControlName="uiVerificationCode" id="uiVerificationCode" />
    </div>
    <div class="buttons">
      <button id="backButton" type="button" (click)="backButtonClick()">Back</button>
      <button id="createUser" type="button" [disabled]="!isCodeValid() || isProcessing" (click)="createUser()">Create User</button>
    </div>
  </form>
</div>
<!-- <div style="margin-top: 20px;">
  {{userForm.value | json}}
</div> -->
