import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ChangeEmailPage } from 'src/app/enums/change-email-page.enum';
import { ApiService } from 'src/app/services/api.service';
import { SendVerificationCodeRequest } from 'src/app/models/requests/login/SendVerificationCodeRequest';
import { UpdateEmailAddressRequest } from 'src/app/models/requests/profile/UpdateEmailAddressRequest';
import { UserData } from 'src/app/dto/user-data';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  @Input() emailAddress: string = '';
  @Output() verificationEvent = new EventEmitter<boolean>();
  isProcessing: boolean = false;
  dialogResult: boolean = false;
  formCode: FormGroup;
  statusMessage: string = '';
  verificationCode: string = '';
  serviceResponse: any;
  currentPage: ChangeEmailPage = ChangeEmailPage.VerificationCode;
  ChangeEmailPage = ChangeEmailPage;
  currentUser: UserData | null = null;

  constructor(private fb: FormBuilder, private api: ApiService,
    private userService: UserService, private router: Router) { 

    this.currentUser = userService.currentUser;
    
    this.formCode = this.fb.group({
      uiVerificationCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]]
    });

  }

  ngOnInit() {
    this.sendCode(this.currentUser);
  }

  async sendCode(userData: UserData | null) {
    if (userData === null ) return;
    this.statusMessage = 'Sending validation code...';
    this.isProcessing = true;

    this.verificationCode = "000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); }).toUpperCase();

    let request: SendVerificationCodeRequest = new SendVerificationCodeRequest();
    request.FirstName = userData.firstName;
    request.LastName = userData.lastName;
    request.EmailAddress = this.emailAddress;
    request.Code = this.verificationCode;
    request.IsEmailVerification = true;

    let response = await this.api.SendVerificationCode(request);
    if (response.RequestSuccessful) {
      this.isProcessing = false;
      this.statusMessage = '';
      this.currentPage = ChangeEmailPage.VerificationCode;
    }
    else {
      this.statusMessage = 'Error: ' + this.serviceResponse.ErrorMessage;
    }
  }

  checkCode() {
    let uiVerificationCode = this.formCode.get('uiVerificationCode')?.value;
    if (this.verificationCode === uiVerificationCode) {
      this.statusMessage = '';
      this.currentPage = ChangeEmailPage.UpdateEmailAddress;
    }
    else {
      this.statusMessage = 'Incorrect verification code.'
    }
  }

  async changeEmailAddress() {
    let newEmailAddress = this.emailAddress;
    this.statusMessage = 'Changing email address...';
    this.isProcessing = true;

    let request: UpdateEmailAddressRequest = new UpdateEmailAddressRequest();
    request.UserId = this.currentUser !== null? this.currentUser.userId : null;
    request.EmailAddress = newEmailAddress;

    let response = await this.api.UpdateEmailAddress(request);
    if (response.RequestSuccessful) {
      this.statusMessage = 'Email address changed successfully...';
      this.currentPage = ChangeEmailPage.Success;
      this.isProcessing = false;
      if (this.currentUser !== null) {
        this.currentUser.emailAddress = newEmailAddress;
      }
      this.userService.currentUser = this.currentUser;
    }
    else {
      this.statusMessage = 'Error: ' + response.ErrorMessage;
    }
  }

  setDialogStatus(success: boolean) {
    this.dialogResult = success;
    this.verificationEvent.emit(this.dialogResult);
  }

  cancelDialog() {
    this.setDialogStatus(false);
  }

  closeDialog() {
    this.setDialogStatus(true);
  }

}
