import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LoginRequest } from 'src/app/models/requests/login/LoginRequest';
import { LoginResponse } from 'src/app/models/responses/login/LoginResponse';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  loginStatus: any;
  loginResponse: LoginResponse = new LoginResponse();
  loading: boolean = false;


  constructor(private api: ApiService,
    private formBuilder: FormBuilder,
    private sidebar: SidebarService,
    private router: Router) {

      this.loginForm = this.formBuilder.group({
        userName: ['', [Validators.required, Validators.minLength(2)]],
        password: ['', [Validators.required, Validators.minLength(2)]]
      });
  
  }

  ngOnInit() {
    // this.sidebar.showRandomExamQuestion();
  }

  async resetLoginForm() {
    this.loginForm.reset();
    this.loginStatus = null;
  }

  get form() {
    return this.loginForm.controls;
  }

  async loginUser(form: FormGroup) {
    this.loading = true;
    var userName = form.value.userName;
    var password = form.value.password;
    this.loginStatus = "Checking credentials.  Please wait...";
    if (!form.controls['userName'].errors && !form.controls['password'].errors) {
      var request = new LoginRequest();
      request.Username = userName;
      request.Password = password;

      this.loginResponse = await this.api.Login(request);
      if (this.loginResponse.RequestSuccessful === true) {
        this.loginStatus = this.loginResponse.ValidUser == null ? "Username/password invalid." : "Login successful!";

        if (this.loginResponse.RequestSuccessful && this.loginResponse.ValidUser != null) {
          setTimeout(() => {
            form.reset({
              userName: '',
              password: ''
            });
            this.loginStatus = '';
            if (this.api.previousUrl == '/login')
            {
              this.router.navigate(['/']);
            }
            else {
              this.router.navigate([this.api.previousUrl]);
            }
          }, 5000);
        }
      }
      else {
        this.loginStatus = "Server Error.  Please try again later.";
      }
    }
    this.loading = false;
  }
}
