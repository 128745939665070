import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SendEmailRequest } from 'src/app/models/requests/login/SendEmailRequest';
import { SendEmailResponse } from 'src/app/models/responses/SendEmailResponse';
import { ApiService } from 'src/app/services/api.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  pageName: string = 'contact';

  public formContact: FormGroup;
  public statusMessage: any = '';
  isProcessing: boolean = false;

  constructor(private sidebar: SidebarService, private api: ApiService, private fb: FormBuilder) {
    this.formContact = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]], 
      emailAddress: ['', [Validators.email, Validators.required]], 
      confirmEmailAddress: ['', [Validators.email, Validators.required]], 
      subject: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]], 
      message: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]], 
    }, {
      validator: [
        this.fieldsMatchValidator('emailAddress', 'confirmEmailAddress')
      ]
    }
    );

  }

  ngOnInit() {
    // this.sidebar.showRandomExamQuestion();
  }

  async sendMessage() {
    this.isProcessing = true;
    this.statusMessage = "";

    let fullName = this.formContact.controls["fullName"].value;
    let emailAddress = this.formContact.controls["emailAddress"].value;
    let subject = this.formContact.controls["subject"].value;
    let message = this.formContact.controls["message"].value;

    if (fullName == null || fullName == "") {
      this.statusMessage = "A name must be provided. ";
    }
    if (emailAddress == null || emailAddress == "") {
      this.statusMessage += "An email address must be provided. ";
    }
    if (this.statusMessage == "")
    {
      var request = new SendEmailRequest();
      request.FromName = fullName;
      request.FromAddress = emailAddress;
      request.Subject = subject;
      request.Body = message;

      let response = await this.api.SendEmail(request);
      this.statusMessage = response === undefined ? "Invalid server response." : response.ErrorMessage;
      if (response !== undefined && response.RequestSuccessful === true)
      {
        this.statusMessage = "Email sent successfully!";

        setTimeout(() => {
          if (this.formContact !== null) {
            this.formContact.reset({
              fullName: '',
              emailAddress: '',
              subject: '',
              message: ''
            });
            this.statusMessage = '';
            }
          }, 5000);
      }
      this.isProcessing = false;
    }
  }

  fieldsMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['fieldsMatchValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ fieldsMatchValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}
