import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public displaySidebar: boolean = true;

  constructor(private api: ApiService) { }
  
  setSidebarData(data: string) {
    var sidebar = document.getElementById('sidebar');
    if (sidebar) {
      if (this.displaySidebar === true) {
        sidebar.innerHTML = data;
      } else {
        sidebar.innerHTML = "";
      }
    }
  }

}
