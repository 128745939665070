import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LoginUser } from '../../models/LoginUser';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public title = environment.title;
  public pageDate = new Date();
  public loginUser: LoginUser | null = null;
  public isUserLoggedIn: boolean = false;

  constructor(private api: ApiService, private userService: UserService) {
  }

  ngOnInit() {
    let self = this;
    this.userService.currentUserChange.subscribe(
      user => {
        self.loginUser = user;
        self.isUserLoggedIn = user != null && user.username != undefined;
      });
  }

  logout() {
    this.userService.currentUser = null;
  }

  userIsLoggedIn() {
    let isUserLoggedIn = this.loginUser != null && this.loginUser.username != undefined;
    return isUserLoggedIn;
  }

}
