import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from 'src/app/dto/user-data';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {

  currentUser: UserData | null = null;

  constructor(private router: Router, private userService: UserService) {
    this.userService.currentUserChange.subscribe((user: UserData | null) => {
      this.currentUser = user;
    });
   }

  ngOnInit() {
    this.currentUser = this.userService.currentUser;
  }

  logoutClick() {
    this.userService.currentUser = null;
    this.router.navigate(['/']);
  }

}
