import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserData } from 'src/app/dto/user-data';
import { UpdatePasswordRequest } from 'src/app/models/requests/profile/UpdatePasswordRequest';
import { UpdateUsernameRequest } from 'src/app/models/requests/profile/UpdateUsernameRequest';
import { UpdatePasswordResponse } from 'src/app/models/responses/profile/UpdatePasswordResponse';
import { UpdateUsernameResponse } from 'src/app/models/responses/profile/UpdateUsernameResponse';
import { ApiService } from 'src/app/services/api.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { UserService } from 'src/app/services/user.service';
import { EmailVerificationComponent } from '../email-verification/email-verification.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isEmailVerified: boolean = false;

  formEmailAddress: FormGroup;
  formPassword: FormGroup;
  formUsername: FormGroup;

  usernameStatus: string = '';
  emailStatus: string = '';
  passwordStatus: string = '';
  isProcessing: boolean = false;
  currentUser: UserData | null = null;
  serviceResponse: any;
  showEmailVerification: boolean = false;


  fieldsMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['fieldsMatchValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ fieldsMatchValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  receiveEmailVerificationStatus($event: boolean) {
    this.isEmailVerified = $event;
    if ($event) {
      if (this.currentUser != null) {
        this.currentUser.emailAddress = this.formEmailAddress?.get('newEmailAddress')?.value;
      }
      this.userService.currentUser = this.currentUser;
    }
    this.showEmailVerification = false;
  }

  constructor(private fb: FormBuilder, private api: ApiService, private sidebar: SidebarService,
    private userService: UserService, private router: Router, private http: HttpClient) {

    this.currentUser = this.userService.currentUser;

    this.formPassword = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: [
        this.fieldsMatchValidator('newPassword', 'confirmPassword')
      ]
    }
    );

    this.formEmailAddress = this.fb.group({
      newEmailAddress: [this.currentUser?.emailAddress, [Validators.required, Validators.email]],
    });
    this.formUsername = this.fb.group({
      newUsername: [this.currentUser?.username, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
    });
}

  ngOnInit() {
    if (this.userService.currentUser === null) {
      this.router.navigate(['/', 'unauthorized']);
    } else {
      //this.sidebar.showRandomExamQuestion();
    }
  }

  setShowEmailVerification() {
    this.showEmailVerification = true;
  }

  async changePassword() {
    this.userService.checkUserTimedOut();

    let newPassword = this.formPassword?.get('newPassword')?.value;
    let emailAddress = this.currentUser === null? '' : this.currentUser.emailAddress;
    this.passwordStatus = 'Changing password...';
    this.isProcessing = true;

    let request: UpdatePasswordRequest = new UpdatePasswordRequest();
    request.NewPassword = newPassword;
    request.EmailAddress = emailAddress;

    let response: UpdatePasswordResponse = await this.api.UpdatePassword(request);
    if (response.RequestSuccessful) {
        this.passwordStatus = 'Password changed successfully...';
        setTimeout(() => {
          this.passwordStatus = '';
          this.isProcessing = false;
          this.formPassword?.reset();
        }, 3000);
    } else {
      this.passwordStatus = this.serviceResponse.ErrorMessage;
    }
  }

  async changeUsername() {
    this.userService.checkUserTimedOut();

    let newUsername = this.formUsername?.get('newUsername')?.value;
    let userId = this.currentUser === null? 0 : this.currentUser.userId;
    this.usernameStatus = 'Changing username...';
    this.isProcessing = true;

    let request: UpdateUsernameRequest = new UpdateUsernameRequest();
    request.Username = newUsername;
    request.UserId = userId;

    try {
      let response: UpdateUsernameResponse = await this.api.UpdateUsername(request);
      if (response.RequestSuccessful) {

        if (response.ErrorMessage !== '' || response.UserAlreadyExists) {
          if (this.serviceResponse.UserAlreadyExists) {
            this.usernameStatus = 'User already exists.';
          } else {
            this.usernameStatus = this.serviceResponse.ErrorMessage;
          }
        } else {
          this.usernameStatus = 'Username changed successfully...';
          setTimeout(() => {
            this.usernameStatus = '';
            this.isProcessing = false;
            this.formUsername?.reset();
            this.formUsername?.get('newUsername')?.setValue(newUsername);
            if (this.currentUser !== null) {
              this.currentUser.username = this.formUsername?.get('newUsername')?.value;
            }
            this.userService.currentUser = this.currentUser;
          }, 3000);
        }
      } else {
        this.usernameStatus = response.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }
}
