import { Component, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserData } from 'src/app/dto/user-data';
import { UserRoles } from 'src/app/enums/login.enum';
import { AddUserRequest } from 'src/app/models/requests/login/AddUserRequest';
import { SendVerificationCodeRequest } from 'src/app/models/requests/login/SendVerificationCodeRequest';
import { UserInfoRequest } from 'src/app/models/requests/login/UserInfoRequest';
import { AddUserResponse } from 'src/app/models/responses/login/AddUserResponse';
import { UserInfoResponse } from 'src/app/models/responses/login/UserInfoResponse';
import { ApiService } from 'src/app/services/api.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  
  userForm: FormGroup;
  codeForm: FormGroup;

  verificationCodeMatchStatus: string = '';
  statusMessage: string = '';
  showUserForm: boolean = true;
  isProcessing: boolean = false;
  serviceResponse: any = {};
  verificationCode: string = '';

  constructor(private fb: FormBuilder, private api: ApiService, private router: Router, private userService: UserService,
    private sidebar: SidebarService) { 
      this.userForm = this.fb.group({
        username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        password: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
        emailAddress: ['', [Validators.required, Validators.email]],
        confirmEmailAddress: ['', [Validators.required, Validators.email]]
      }, {
        validator: [
          this.fieldsMatchValidator('emailAddress', 'confirmEmailAddress'),
          this.fieldsMatchValidator('password', 'confirmPassword')
        ]
      } as AbstractControlOptions,
      );

      this.codeForm = this.fb.group({
        uiVerificationCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]]
      });
    }

  ngOnInit() {
    //this.sidebar.showRandomExamQuestion();
  }

  ngAfterViewInit() {
    //this.focusUserName();
  }

  focusUserName() {
    let ctrl = document.getElementById('username');
    if (ctrl != null) {
      ctrl.focus();
    }
  }

  async sendCode() {
    this.statusMessage = 'Sending validation code...';
    this.isProcessing = true;

    this.verificationCode = "000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); }).toUpperCase();


    let firstName = this.userForm.get('firstName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let emailAddress = this.userForm.get('emailAddress')?.value;

    let request: SendVerificationCodeRequest = new SendVerificationCodeRequest();
    request.FirstName = firstName;
    request.LastName = lastName;
    request.EmailAddress = emailAddress;
    request.Code = this.verificationCode;
    request.IsEmailVerification = true;

    try {
      let response = await this.api.SendVerificationCode(request);
      if (response.RequestSuccessful) {
        this.statusMessage = '';
        this.showUserForm = false;
      }
      else {
        this.statusMessage = 'Error: ' + response.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }

  backButtonClick() {
    this.showUserForm = true;
  }

  async createUser() {
    this.statusMessage = "Adding user...";
    this.isProcessing = true;

    let username = this.userForm.get('username')?.value;
    let firstName = this.userForm.get('firstName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let emailAddress = this.userForm.get('emailAddress')?.value;
    let password = this.userForm.get('password')?.value;

    let request: AddUserRequest = new AddUserRequest();
    request.Username = username;
    request.FirstName = firstName;
    request.LastName = lastName;
    request.EmailAddress = emailAddress;
    request.Password = password;

    let response: AddUserResponse = await this.api.AddUser(request);
    if (response.RequestSuccessful) {
      if (response.UserAlreadyExists) {
        this.statusMessage = 'Username and/or email address already exists.';
      } else {
        this.statusMessage = 'User successfully created.';
        setTimeout(() => {
          let newUser: UserData = new UserData(null);
          newUser.userId = response.UserId;
          newUser.username = username;
          newUser.firstName = firstName;
          newUser.lastName = lastName;
          newUser.emailAddress = emailAddress;
          newUser.userRole = UserRoles.User;
          newUser.isActive = true;
          this.userService.currentUser = newUser;
          this.statusMessage = '';

          this.router.navigate(['/']);
        })
      }

    }
    else {
      this.statusMessage = 'Error: ' + this.serviceResponse.ErrorMessage;
    }

  }

  async validateUser() {
    this.statusMessage = "Validating user...";
    this.isProcessing = true;

    let username = this.userForm.get('username')?.value;
    let emailAddress = this.userForm.get('emailAddress')?.value;

    let request: UserInfoRequest = new UserInfoRequest();
    request.Username = username;
    request.EmailAddress = emailAddress;

    let response: UserInfoResponse = await this.api.UserInfo(request);
    if (response.RequestSuccessful) {
      if (response.UserInfo === null) {
        await this.sendCode();
      } else {
        this.statusMessage = 'Username and/or email address already exists.';
      }
    }
    else {
      this.statusMessage = 'Error: ' + this.serviceResponse.ErrorMessage;
    }

  }

  //
  //  Custom validator that takes a comma separated list of text that is not valid inside an email message.
  //
  invalidEmailMessage(items: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      var message = control.value;
      var valid = true;

      if (message) {
        if ((message !== null) && (items.split(',').length > 0)) {
          message = message.toLowerCase();
          items.split(',').forEach(item => {
            if (message.indexOf(item.toLowerCase()) >= 0) {
              valid = false;
            }
          });
        }
      }

      if (valid) {
        return null;
      }
      return { 'invalidEmailMessage': true };
    }
  }

  fieldsMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['fieldsMatchValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ fieldsMatchValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  isCodeValid() {
    let uiVerificationCode = this.codeForm.get('uiVerificationCode')?.value;
    return this.verificationCode === uiVerificationCode;
  }

}
