import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { MenuBarComponent } from './layout/menubar/menubar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginMenuComponent } from './layout/login/login-menu/login-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { PageStatusComponent } from './components/page-status/page-status.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './layout/login/login.component';
import { NewUserComponent } from './layout/login/new-user/new-user.component';
import { ForgotPasswordComponent } from './layout/login/forgot-password/forgot-password.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { EmailVerificationComponent } from './admin/email-verification/email-verification.component';
import { AdminComponent } from './admin/admin.component';

@NgModule({
  declarations: [
    AdminComponent,
    AppComponent,
    ContactComponent,
    EmailVerificationComponent,
    FooterComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    HomeComponent,
    MenuBarComponent,
    MenuComponent,
    SidebarComponent,
    LoginComponent,
    LoginMenuComponent,
    NewUserComponent,
    ProfileComponent,
    PageStatusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
