import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { lastValueFrom, Subject } from 'rxjs';
import { UserData } from '../dto/user-data';
import { SetLastAccessRequest } from '../models/requests/login/SetLastAccessRequest';
import { SetLastAccessResponse } from '../models/responses/login/SetLastAccessResponse';
import { LocalStorageService } from './local-storage.service';
import { ServiceRequest } from '../models/requests/ServiceRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUserChange: Subject<UserData | null> = new Subject<UserData | null>();
  private authKey = environment.authPwd;
  private authUser = environment.authUser;
  private jsonHeader = new HttpHeaders({ 'content-type': 'application/json' });

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService, private router: Router) {
    this.currentUserChange.subscribe((user: UserData | null) => {
      this.localStorageService.set("CurrentUser", JSON.stringify(user));
    })
  }
  
  get currentUser(): UserData | null {
    let result: UserData | null = null;
    let userJson: string = this.localStorageService.get("CurrentUser");
    if (userJson !== null && userJson !== 'null' && userJson !== '') {
      result = new UserData(userJson);
      if (result.userId <= 0) {
        return null;
      }
    }
    return result;
  }

  set currentUser(user: UserData | null) {
    this.currentUserChange.next(user);
  }

  checkUserTimedOut(): boolean {
    let result = false;
    if (this.currentUser !== null) {
      if (this.currentUser.timedOut) {
        this.currentUser = null;
      } else {
        this.setLastAccess();
      }
    }
    return result;
  }

  stringToUnicodeBytes(str: string) {
    //strToUtf16Bytes(str) {
    const bytes = [];
    for (let ii = 0; ii < str.length; ii++) {
      const code = str.charCodeAt(ii); // x00-xFFFF
      bytes.push(code & 255, code >> 8); // low, high
    }
    return bytes;
  }

  private async setLastAccess() {

    if (this.currentUser !== null) {
      let request: SetLastAccessRequest = new SetLastAccessRequest();
      request.UserId = this.currentUser.userId;

      let response: SetLastAccessResponse = await this.DoSetLastAccess(request);
      if (response.RequestSuccessful) {
        // Get a copy of the current user, update the lastAccess date
        // of the temp user and then replace the current user with the updated one.
        let tempUser = this.currentUser;
        let lastAccess = response.LastAccess;
        let lastAccessDate = this.currentUser.parseDateTime(lastAccess);
        tempUser.lastAccess = lastAccessDate;
        this.currentUser = tempUser;
      }
      else {
        alert('Error setting last access: ' + response.ErrorMessage);
      }
    }
  }

  async DoSetLastAccess(request: SetLastAccessRequest): Promise<SetLastAccessResponse> {
    var response: SetLastAccessResponse = new SetLastAccessResponse();
    try {

      var url = environment.apiServiceUrl

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'SetLastAccess',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<SetLastAccessResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

}
