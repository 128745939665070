<div id="header">
  <div>
    <a href="/"><img alt="More Stuff" src="assets/img/morestuff.png" /></a>
  </div>
  <div>
    <app-login-menu></app-login-menu>
    <!-- <div id="pageDate">{{pageDate | date: 'EEEE, MMMM d, yyyy'}}</div>
    <div>{{title}}</div> -->
  </div>
</div>
