import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { PasswordResetStatus } from 'src/app/enums/password-reset-status.enum';
import { ApiService } from 'src/app/services/api.service';
import { UserInfoRequest } from 'src/app/models/requests/login/UserInfoRequest';
import { UserInfoResponse } from 'src/app/models/responses/login/UserInfoResponse';
import { SendUsernameRequest } from 'src/app/models/requests/login/SendUsernameRequest';
import { SendUsernameResponse } from 'src/app/models/responses/login/SendUsernameResponse';
import { SendVerificationCodeResponse } from 'src/app/models/responses/login/SendVerificationCodeResponse';
import { UpdatePasswordRequest } from 'src/app/models/requests/profile/UpdatePasswordRequest';
import { UpdatePasswordResponse } from 'src/app/models/responses/profile/UpdatePasswordResponse';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  formCode: FormGroup;
  formChangePassword: FormGroup;
  isProcessing = false;
  statusMessage = '';
  serviceResponse: any = {};
  resetStatus: PasswordResetStatus = PasswordResetStatus.NotSet;
  PasswordResetStatus = PasswordResetStatus;
  verificationCode = '';
  uiVerificationCode = '';
  AuthUser = environment.authUser;
  AuthPwd = environment.authPwd;

  fieldsMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['fieldsMatchValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ fieldsMatchValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  constructor(private fb: FormBuilder, private api: ApiService,
    private userService: UserService, private router: Router, private sidebar: SidebarService) {
    
    this.form = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      confirmEmailAddress: ['', [Validators.required, Validators.email]]
    }, {
      validator: [
        this.fieldsMatchValidator('emailAddress', 'confirmEmailAddress')
      ]
    },
    );

    this.formCode = this.fb.group({
      uiVerificationCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]]
    });

    this.formChangePassword = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]]
    }, {
      validator: [
        this.fieldsMatchValidator('newPassword', 'confirmPassword')
      ]
    },
    );

  }

  ngOnInit() {
     // this.sidebar.showRandomExamQuestion();
  }

  async resetPassword() {
    this.statusMessage = "Looking for user credentials...";
    this.isProcessing = true;

    let emailAddress = this.form.get('emailAddress')?.value;

    let request: UserInfoRequest = new UserInfoRequest();
    request.Username = '';
    request.EmailAddress = emailAddress;

    try {
      let response: UserInfoResponse = await this.api.UserInfo(request);
      if (response !== undefined && response.RequestSuccessful === true) {
        if (response.UserInfo !== null) {
          this.statusMessage = "User found.  Please check your email...";
          this.sendCode(response.UserInfo);
          this.resetStatus = PasswordResetStatus.ShowVerificationCode;
        } else {
          this.statusMessage = "User not found matching the specified email address.";
        }
      } else {
        this.statusMessage = 'Error: ' + this.serviceResponse.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }

  async sendUserName() {
    this.statusMessage = "Looking for user credentials...";
    this.isProcessing = true;

    let emailAddress = this.form.get('emailAddress')?.value;

    let request: SendUsernameRequest = new SendUsernameRequest();
    request.EmailAddress = emailAddress;

    try {
      let response: SendUsernameResponse = await this.api.SendUsername(request);
      if (response !== undefined && response.RequestSuccessful === true && response.UserExists) {
        this.statusMessage = "Username sent.  Please check your email...";
        setTimeout(() => {
          this.statusMessage = "";
          this.router.navigate(['/', 'login']);
        }, 3000);
      } else {
        this.statusMessage = 'Error: ' + response.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }

  async sendCode(userData: any) {
    this.statusMessage = 'Sending validation code...';
    this.isProcessing = true;

    this.verificationCode = "000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); }).toUpperCase();

    let request: any = {};
    request.AuthUser = environment.authUser;
    request.AuthPwd = environment.authPwd;
    request.FirstName = userData.FirstName;
    request.LastName = userData.LastName;
    request.EmailAddress = userData.EmailAddress;
    request.Code = this.verificationCode;
    request.IsEmailVerification = false;

    try {
      let response: SendVerificationCodeResponse = await this.api.SendVerificationCode(request);
      if (response !== undefined && response.RequestSuccessful === true) {
        if (this.serviceResponse.ErrorMessage != '') {
          this.statusMessage = this.serviceResponse.ErrorMessage;
        } else {
          this.statusMessage = '';
          this.resetStatus = PasswordResetStatus.ShowVerificationCode;
        }
      } else {
        this.statusMessage = 'Error: ' + this.serviceResponse.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }

  checkCode() {
    this.uiVerificationCode = this.formCode.get('uiVerificationCode')?.value;
    if (this.verificationCode === this.uiVerificationCode) {
      this.resetStatus = PasswordResetStatus.ChangePasswordForm;
      this.statusMessage = '';
    }
    else {
      this.statusMessage = 'Incorrect verification code.'
    }
  }

  async changePassword() {
    let newPassword = this.formChangePassword.get('newPassword')?.value;
    let emailAddress = this.form.get('emailAddress')?.value;
    this.statusMessage = 'Changing password...';
    this.isProcessing = true;

    let request: UpdatePasswordRequest = new UpdatePasswordRequest();
    request.NewPassword = newPassword;
    request.EmailAddress = emailAddress;

    try {
      let response: UpdatePasswordResponse = await this.api.UpdatePassword(request);
      if (response.RequestSuccessful === true) {
        this.statusMessage = 'Password changed successfully...';
        this.resetStatus = PasswordResetStatus.ChangePasswordSuccess;
        setTimeout(() => {
          this.router.navigate(['/', 'login']);
        }, 3000);
      } else {
        this.statusMessage = response.ErrorMessage;
      }
    }
    finally {
      this.isProcessing = false;
    }
  }
}
