<div id="emailVerification">
<form id="verificationCode" [formGroup]="formCode" *ngIf="currentPage === ChangeEmailPage.VerificationCode">
  <p>Email validation sent to:</p>
  <p>{{emailAddress}}</p>
  <p>Please check your email and provide the verification code found in the email.
    Check your spam folder if you don't see the verification email.
  </p>
  <label for="verificationCode">Verification Code:</label>
  <input type="text" id="uiVerificationCode" formControlName="uiVerificationCode" />
  <div *ngIf="statusMessage != ''">
    <img src="/assets/img/wait24trans.gif" *ngIf="isProcessing" />
    <div [innerHTML]="statusMessage"></div>
  </div>
  <div id="buttons">
    <button id="cancel" type="button" (click)="cancelDialog()">Cancel</button>
    <button id="checkCode" type="button" [disabled]="formCode.invalid || isProcessing" (click)="checkCode()">Check</button>
  </div>
</form>
<div id="updateEmailAddress" *ngIf="currentPage === ChangeEmailPage.UpdateEmailAddress">
  <div>Email address verified.</div>
  <div>Update email address to:</div>
  <div>{{emailAddress}}</div>
  <div *ngIf="statusMessage != ''">
    <img src="/assets/img/wait24trans.gif" *ngIf="isProcessing" />
    <div [innerHTML]="statusMessage"></div>
  </div>
  <div id="buttons">
    <button id="cancel" type="button" (click)="cancelDialog()">Cancel</button>
    <button type="button" (click)="changeEmailAddress()">Yes</button>
  </div>
</div>
<div id="successPage" *ngIf="currentPage === ChangeEmailPage.Success">
  <div>Email address successfully changed to:</div>
  <div>{{emailAddress}}</div>
  <button type="button" (click)="closeDialog()">Close</button>
</div>
</div>

