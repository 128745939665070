<div id="contactPage">
  <h1>Contact Us</h1>
  <!-- <img alt="Contact More Stuff" src="/assets/img/contactus.gif"> -->

<app-page-status pageName="{{pageName}}"></app-page-status>

  <p>Please check and double-check your email address before submitting your request.&nbsp;
    I often receive questions only to find that the email address has been incorrectly
    entered and then I have no way of responding to the request.&nbsp; If you haven't
    received a response from me and it has been more than a couple of days then please
    recheck the email address and send your question again.</p>

  <div id="contact">
    <form [formGroup]="formContact">
      <div>
        <span *ngIf="formContact.controls['fullName'].invalid || !formContact.controls['fullName'].dirty">*</span>
        <label for="fullName">Full Name:</label>
        <div *ngIf="formContact.controls['fullName'].invalid && formContact.controls['fullName'].dirty">
          <span>A Name is required.</span>
        </div>
        <input id="fullName" type="text" formControlName="fullName">
      </div>
      <div><span *ngIf="formContact.controls['emailAddress'].invalid || !formContact.controls['emailAddress'].dirty">*</span>
        <label for="emailAddress">Email Address</label>:<br />
        <div *ngIf="formContact.controls['emailAddress'].invalid && formContact.controls['emailAddress'].dirty">
          <span>A valid email address is required.</span>
        </div>
        <input id="emailAddress" type="text" formControlName="emailAddress">
      </div>
      <div><label for="confirmEmailAddress">Confirm Email Address:</label></div>
      <div>
        <span *ngIf="formContact.get('confirmEmailAddress')?.invalid && (formContact.get('confirmEmailAddress')?.touched || formContact.get('confirmEmailAddress')?.dirty)">
          The confirmation email address is required and must match the email address.</span>
      </div>
      <div><input id="confirmEmailAddress" type="email" formControlName="confirmEmailAddress" /></div>
      <div><span *ngIf="formContact.controls['subject'].invalid || !formContact.controls['subject'].dirty">*</span>
        <label for="Subject">Subject</label>:<br />
        <div *ngIf="formContact.controls['subject'].invalid && formContact.controls['subject'].dirty">
          <span>The subject is required.</span>
        </div>
        <input id="subject" type="text" formControlName="subject">
      </div>
      <div><span *ngIf="formContact.controls['message'].invalid || !formContact.controls['message'].dirty">*</span>
        <label for="message">Message</label>:<br />
        <div *ngIf="formContact.controls['message'].invalid && formContact.controls['message'].dirty">
          <span>A message is required.</span>
        </div>
        <textarea id="message" formControlName="message"></textarea>
      </div>
      <span *ngIf="!formContact.valid">* Required Fields</span>
      <div id="statusMessage"><span>{{statusMessage}}</span></div>
      <div id="contactButtons">
        <button type="reset">Clear Form</button>
        <button id="sendButton" type="submit" [disabled]="formContact.invalid || isProcessing" (click)="sendMessage()">Send Message</button>
      </div>
    </form>
  </div>
</div>
