import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SendEmailResponse } from '../models/responses/SendEmailResponse';
import { SendEmailRequest } from '../models/requests/login/SendEmailRequest';
import { environment } from '../../environments/environment';
import { LoginRequest } from '../models/requests/login/LoginRequest';
import { LoginResponse } from '../models/responses/login/LoginResponse';
import { AddUserRequest } from '../models/requests/login/AddUserRequest';
import { AddUserResponse } from '../models/responses/login/AddUserResponse';
import { ForgotPasswordRequest } from '../models/requests/login/ForgotPasswordRequest';
import { ForgotPasswordResponse } from '../models/responses/login/ForgotPasswordResponse';
import { LoginUser } from '../models/LoginUser';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { SendVerifyEmailRequest } from '../models/requests/login/SendVerifyEmailRequest';
import { SendVerifyEmailResponse } from '../models/responses/login/SendVerifyEmailResponse';
import { Router, NavigationEnd } from '@angular/router';
import { ServiceRequest } from '../models/requests/ServiceRequest';
import { UserService } from './user.service';
import { UserData } from '../dto/user-data';
import { UserInfoRequest } from '../models/requests/login/UserInfoRequest';
import { UserInfoResponse } from '../models/responses/login/UserInfoResponse';
import { SendUsernameRequest } from '../models/requests/login/SendUsernameRequest';
import { SendUsernameResponse } from '../models/responses/login/SendUsernameResponse';
import { SendVerificationCodeRequest } from '../models/requests/login/SendVerificationCodeRequest';
import { SendVerificationCodeResponse } from '../models/responses/login/SendVerificationCodeResponse';
import { UpdatePasswordRequest } from '../models/requests/profile/UpdatePasswordRequest';
import { UpdatePasswordResponse } from '../models/responses/profile/UpdatePasswordResponse';
import { UpdateEmailAddressRequest } from '../models/requests/profile/UpdateEmailAddressRequest';
import { UpdateEmailAddressResponse } from '../models/responses/profile/UpdateEmailAddressResponse';
import { UpdateUsernameRequest } from '../models/requests/profile/UpdateUsernameRequest';
import { UpdateUsernameResponse } from '../models/responses/profile/UpdateUsernameResponse';
import { PageStatusesRequest } from '../models/requests/PageStatus/PageStatusesRequest';
import { PageStatusesResponse } from '../models/responses/PageStatus/PageStatusesResponse';
import { PageStatusRequest } from '../models/requests/PageStatus/PageStatusRequest';
import { PageStatusResponse } from '../models/responses/PageStatus/PageStatusResponse';
import { DeletePageStatusRequest } from '../models/requests/PageStatus/DeletePageStatusRequest';
import { DeletePageStatusResponse } from '../models/responses/PageStatus/DeletePageStatusResponse';
import { InsertPageStatusRequest } from '../models/requests/PageStatus/InsertPageStatusRequest';
import { InsertPageStatusResponse } from '../models/responses/PageStatus/InsertPageStatusResponse';
import { UpdatePageStatusRequest } from '../models/requests/PageStatus/UpdatePageStatusRequest';
import { UpdatePageStatusResponse } from '../models/responses/PageStatus/UpdatePageStatusResponse';
import { LastUpdatedResponse } from '../models/responses/LastUpdatedResponse';
import { LastUpdatedRequest } from '../models/requests/LastUpdatedRequest';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  serviceUrl: string = environment.apiServiceUrl;
  private userSource = new BehaviorSubject(new LoginUser());
  loginUser = this.userSource.asObservable();
  public previousUrl: string = "/";
  public currentUrl: string = "/";
  private jsonHeader = new HttpHeaders({ 'content-type': 'application/json' });

  private authKey = environment.authPwd;
  private authUser = environment.authUser;

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = e.url;
        //console.log("previous Url: ", this.previousUrl);
        //console.log("current Url: ", this.currentUrl);
      }
    });

  }

  stringToUnicodeBytes(str: string) {
    //strToUtf16Bytes(str) {
    const bytes = [];
    for (let ii = 0; ii < str.length; ii++) {
      const code = str.charCodeAt(ii); // x00-xFFFF
      bytes.push(code & 255, code >> 8); // low, high
    }
    return bytes;
  }

  public async SendEmail(request: SendEmailRequest): Promise<SendEmailResponse> {

    var response: SendEmailResponse = new SendEmailResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'Email',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<SendEmailResponse>(url, JSON.stringify(serverRequest), {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async Login(request: LoginRequest): Promise<LoginResponse> {
    var response: LoginResponse = new LoginResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serviceRequest: ServiceRequest = {
        Command: 'Login',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      };

      response = await lastValueFrom(this.httpClient.post<LoginResponse>(url, serviceRequest, {
        headers: this.jsonHeader
      }));

      if (response.RequestSuccessful && response.ValidUser != null) {
        let userData = new UserData(response.ValidUser);
        this.userService.currentUser = userData;
      }

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;

  }

  public async SendVerifyEmail(request: SendVerifyEmailRequest): Promise<SendVerifyEmailResponse> {

    var response: SendVerifyEmailResponse = new SendVerifyEmailResponse();
    try {

      var url = this.serviceUrl + "user/sendverificationemail";

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      response = await lastValueFrom(this.httpClient.post<SendVerifyEmailResponse>(url, request, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async AddUser(request: AddUserRequest): Promise<AddUserResponse> {
    var response: AddUserResponse = new AddUserResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serviceRequest: ServiceRequest = {
        Command: 'AddUser',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      };

      response = await lastValueFrom(this.httpClient.post<AddUserResponse>(url, serviceRequest, {
        headers: this.jsonHeader
      }));

      if (response.RequestSuccessful) {
      }

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;

  }


  public async ForgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
    var response: ForgotPasswordResponse = new ForgotPasswordResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serviceRequest: ServiceRequest = {
        Command: 'ForgotPassword',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<ForgotPasswordResponse>(url, serviceRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async UserInfo(request: UserInfoRequest): Promise<UserInfoResponse> {
    var response: UserInfoResponse = new UserInfoResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'UserInfo',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<UserInfoResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async SendUsername(request: SendUsernameRequest): Promise<SendUsernameResponse> {
    var response: SendUsernameResponse = new SendUsernameResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'SendUserName',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<SendUsernameResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async SendVerificationCode(request: SendVerificationCodeRequest): Promise<SendVerificationCodeResponse> {
    var response: SendVerificationCodeResponse = new SendVerificationCodeResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'SendVerificationCode',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<SendVerificationCodeResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async UpdatePassword(request: UpdatePasswordRequest): Promise<UpdatePasswordResponse> {
    var response: UpdatePasswordResponse = new UpdatePasswordResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'UpdatePassword',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<UpdatePasswordResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async UpdateEmailAddress(request: UpdateEmailAddressRequest): Promise<UpdateEmailAddressResponse> {
    var response: UpdateEmailAddressResponse = new UpdateEmailAddressResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'UpdateEmailAddress',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<UpdateEmailAddressResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async UpdateUsername(request: UpdateUsernameRequest): Promise<UpdateUsernameResponse> {
    var response: UpdateUsernameResponse = new UpdateUsernameResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'UpdateUsername',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<UpdateUsernameResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async DeletePageStatus(request: DeletePageStatusRequest): Promise<DeletePageStatusResponse> {
    var response: DeletePageStatusResponse = new DeletePageStatusResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'DeletePageStatus',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<DeletePageStatusResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async InsertPageStatus(request: InsertPageStatusRequest): Promise<InsertPageStatusResponse> {
    var response: InsertPageStatusResponse = new InsertPageStatusResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'InsertPageStatus',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<InsertPageStatusResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async PageStatus(pageName: string): Promise<PageStatusResponse> {
    var request: PageStatusRequest = new PageStatusRequest();
    var response: PageStatusResponse = new PageStatusResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;
      request.PageName = pageName;

      let serverRequest: ServiceRequest = {
        Command: 'PageStatus',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<PageStatusResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async PageStatuses(request: PageStatusesRequest): Promise<PageStatusesResponse> {
    var response: PageStatusesResponse = new PageStatusesResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'PageStatuses',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<PageStatusesResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async UpdatePageStatus(request: UpdatePageStatusRequest): Promise<UpdatePageStatusResponse> {
    var response: UpdatePageStatusResponse = new UpdatePageStatusResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'UpdatePageStatus',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<UpdatePageStatusResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

  public async LastUpdated(): Promise<LastUpdatedResponse> {
    var request: LastUpdatedRequest = new LastUpdatedRequest();
    var response: LastUpdatedResponse = new LastUpdatedResponse();
    try {

      var url = this.serviceUrl;

      request.AuthPwd = this.authKey;
      request.AuthUser = this.authUser;

      let serverRequest: ServiceRequest = {
        Command: 'LastUpdated',
        Options: this.stringToUnicodeBytes(JSON.stringify(request)),
        UseCompression: false
      }

      response = await lastValueFrom(this.httpClient.post<LastUpdatedResponse>(url, serverRequest, {
        headers: this.jsonHeader
      }));

    } catch (error: any) {
      response.RequestSuccessful = false;
      response.ErrorMessage = error.message;
    }

    return response;
  }

}