import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './layout/login/login.component';
import { NewUserComponent } from './layout/login/new-user/new-user.component';
import { ForgotPasswordComponent } from './layout/login/forgot-password/forgot-password.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  { path: "", component: HomeComponent, data: { title: "" } },
  { path: "admin", component: AdminComponent, data: { title: "Admin" } },
  { path: "contact", component: ContactComponent, data: { title: "Contact Us" } },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  { path: "newuser", component: NewUserComponent, data: { title: "New User" } },
  { path: "profile", component: ProfileComponent, data: { title: "Profile" } },
  { path: "forgotpassword", component: ForgotPasswordComponent, data: { title: "New User" } },
  { path: "**", component: PageNotFoundComponent, data: { title: "Page Not Found" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
