import { ServiceRequestBase } from "../ServiceRequestBase";

export class AddUserRequest extends ServiceRequestBase {
  public Username: string | null = null;
  public FirstName: string | null = null;
  public LastName: string | null = null;
  public Password: string | null = null;
  public EmailAddress: string | null = null;
}

